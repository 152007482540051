import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-intl";
import * as React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import apiJobs from "../../api/jobs";
import Jobs from "../../components/Jobs/jobs";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";

interface IProps {}

const Hr = (props: IProps) => {
  const [loading, setLoading] = React.useState(false);
  const [companyId, setCompanyId] = React.useState([]);
  const { formatMessage: fm } = useIntl();
  const [hrs] = React.useState([
    {
      title: fm({ id: "home-hr-desc-1" }),
    },
    {
      title: fm({ id: "home-hr-desc-2" }),
    },
    {
      title: fm({ id: "home-hr-desc-3" }),
    },
    {
      title: fm({ id: "home-hr-desc-4" }),
    },
    {
      title: fm({ id: "home-hr-desc-5" }),
    },
  ]);
  const [steps] = React.useState([
    {
      title: fm({ id: "hr-step-1" }),
    },
    {
      title: fm({ id: "hr-step-2" }),
    },
    {
      title: fm({ id: "hr-step-3" }),
    },
    // {
    //   title: fm({ id: "hr-step-4" }),
    // },
    // {
    //   title: fm({ id: "hr-step-5" }),
    // },
    // {
    //   title: fm({ id: "hr-step-6" }),
    // },
  ]);

  React.useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const res = await apiJobs.findCompany();
      if (res.success) {
        setCompanyId(res.data);
      } else {
        console.log("RES:ERROR:  ", res.e);
      }
      setLoading(false);
    };
    fetch();
  }, []);

  console.log("CompanyID: ", companyId);

  return (
    <>
      <SEO title={fm({ id: "menu-hr" })} />
      <Layout>
        <div>
          <div className="relative z-40">
            <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center transform scale-100">
              <StaticImage
                src="../../assets/HR-1.png"
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60"></div>
            <div className="container relative mx-auto px-4 h-screen flex items-center justify-center text-white">
              <p
                className="text-xl font-cool w-full text-center lg:w-2/3 lg:text-4xl tracking-wider leading-snug text-white"
                data-sal="slide-up"
                data-dal-delay="1000"
                data-sal-duration={2000}
              >
                {fm({ id: "home-hr-title" })}
              </p>
            </div>
          </div>
          <div className="bg-white  ">
            <div className="container min-h-screen p-10  md:flex mx-auto space-y-20 md:space-y-0 pt-20">
              <div className="md:w-3/5">
                <h1
                  className="mb-10 font-cool  text-xl lg:text-2xl  px-5 cursor-pointer"
                  data-sal="fade-up"
                  data-sal-duration={2000}
                >
                  {fm({ id: "home-hr-sub-title" })}
                </h1>
                <div className="flex flex-col lg:flex-row items-center">
                  <ul className="text-left text-base px-10 lg:px-0  lg:text-2xl  text-main w-full lg:w-1/2">
                    {hrs?.map((branch) => (
                      <li
                        className="list-disc font-cool leading-loose "
                        data-sal="fade-up"
                        data-dal-delay="1000"
                        data-sal-duration={2000}
                      >
                        {branch?.title}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:w-2/5">
                <h1
                  className="mb-10 font-cool text-xl lg:text-2xl  px-5 cursor-pointer  "
                  data-sal="fade-up"
                  data-dal-delay="1000"
                  data-sal-duration={2000}
                >
                  {fm({ id: "home-hr-sub-title-1" })}
                </h1>
                <div className="flex flex-col lg:flex-row items-center">
                  <div className="px-2 lg:px-0 w-full lg:w-1/2 ">
                    <div className="relative text-left text-base">
                      <div className="absolute top-0 left-3 bg-main w-0.5 h-full" />
                      {steps?.map((step, i) => (
                        <section className="flex relative items-start font-cool mb-5 space-x-10">
                          <div
                            className={`w-6 h-6 ${
                              i === 0 ? "bg-main" : "bg-white"
                            } rounded-full flex whitespace-nowrap items-center justify-center border-2 border-main`}
                          >
                            {i === 0 && <AiOutlineCheck color="#fff" />}
                            {i === 1 && (
                              <div className="w-2 h-2 bg-main rounded-full"></div>
                            )}
                          </div>
                          <div>
                            <h3 className="text-black text-base md:text-lg mb-2 uppercase text-opacity-60">
                              Step - {i + 1}
                            </h3>
                            <span
                              className="text-lg md:text-xl"
                              data-sal="fade-up"
                              data-dal-delay="1000"
                              data-sal-duration={2000}
                            >
                              {step?.title}
                            </span>
                          </div>
                        </section>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="container min-h-screen p-10 mx-auto">
              {companyId.map((company) => (
                <Jobs {...company} />
              ))}
            </div> */}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Hr;
